.nav-section {
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 1rem;
    z-index: 1;
    background-color: var(--primary-darkest);
    transition: 1s;
    -webkit-box-shadow: 0px 1px 0px 0px #00000000;
    -moz-box-shadow: 0px 1px 0px 0px #00000000;
    box-shadow: 0px 1px 0px 0px #00000000;
}

.nav-section.right {
    border-right: 1px solid var(--primary);
}

.nav-section.left {
    border-left: 1px solid var(--primary);
}

.nav-section.bottom-shadow {
    -webkit-box-shadow: 0px 1px 0px 0px var(--primary);
    -moz-box-shadow: 0px 1px 0px 0px var(--primary);
    box-shadow: 0px 1px 0px 0px var(--primary);
}

.nav-section.bottom {
    border-bottom: 1px solid var(--primary);
}

.nav-section.top {
    border-top: 1px solid var(--primary);
}

.nav-dropdown {
    min-height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 0;
    transition: 1s;
}

.nav-item {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    min-height: 4rem;
}
.nav-item.wrap {
    white-space: normal;
}

.nav-item.animated {
    transition: 0.3s;
}

@media (hover: hover) {
    .nav-item.animated:hover {
        transform: scale(1.1);
    }
}

.nav-item.pointer {
    cursor: pointer;
}

.nav-item.fill-width {
    padding: 0;
    width: 100%;
}