.terminal {
    z-index: 0;
    position: absolute;
    margin-top: 0;
    left: 0;
    right: 0;
    padding: 2rem 1rem 0 1rem;
    height: 14.7rem;

    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: var(--primary-darkest);

    font-family: "SourceCodePro";
}

.terminal.tall {
    height: 0rem;
}

.terminal::after {
    z-index: -5;
    position: absolute;
    right: 0px;
    margin-top: calc(14.7rem - 1px);
    content: "";
    border-bottom: 9rem solid transparent;
    border-left: 100vw solid var(--primary-darkest);
    border-top: 0 solid var(--primary-darkest);
    border-right: 0 solid transparent;
}

.terminal.tall::after {
    margin-top: calc(0rem - 1px);
    border-bottom: 36rem solid transparent;
}

.terminal.short::after {
    border-bottom: 0rem solid transparent;
}

.listbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: "40px";
}

.listbox.collapsed {
    height: auto;
}

line {
    white-space: nowrap;
}

span.secondary {
    color: var(--secondary-lightest);
}
span.tertiary {
    color: var(--tertiary);
}
span.quaternary {
    color: var(--quaternary);
}

span {
    white-space: nowrap;
}