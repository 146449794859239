.navbar {
	position: fixed;
	z-index: 2;

	min-height: 4rem;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	-webkit-box-shadow: 0px 0px 25px 6px var(--primary);
	-moz-box-shadow: 0px 0px 25px 6px var(--primary);
	box-shadow: 0px 0px 25px 6px var(--primary);
	background-color: var(--primary-darkest);

	flex-wrap: wrap;
}

.no-glow {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
