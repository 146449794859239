.side-container {
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
    margin-left: auto;
    background-color: var(--primary-darkest);

    display: flex;
    font-size: 1.4rem;
    border-bottom: 5px solid var(--primary);
}

.pop-text-container {
    padding: 2rem;
    border-right: 5px solid var(--tertiary);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.side-container.primary .pop-text-container {
    border-right: 5px solid var(--primary);
}
.side-container.secondary .pop-text-container {
    border-right: 5px solid var(--secondary);
}
.side-container.tertiary .pop-text-container {
    border-right: 5px solid var(--tertiary);
}
.side-container.quaternary .pop-text-container {
    border-right: 5px solid var(--quaternary);
}

.pop-text {
    font-size: 2.75rem;
    font-family: "SecularOne";
}

.side-content-container {
    padding: 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.side-container.filled {
    max-width: 100% !important;
}

.side-container.shrunk .pop-text-container {
    padding: 1rem 1rem 1rem 0;
}

.side-container.shrunk .pop-text {
    font-size: 2rem;
}

.side-container.shrunk .side-content-container {
    justify-content: center;
    padding: 1rem 0 1rem 1rem;
    font-size: 1.1rem;
}

.side-container.collapsed {
    flex-direction: column;
}

.side-container.collapsed .pop-text-container {
    border-right: none;
    border-bottom: 5px solid var(--tertiary);
    padding: 1rem;
}

.side-container.collapsed.primary .pop-text-container {
    border-bottom: 5px solid var(--primary);
}

.side-container.collapsed.secondary .pop-text-container {
    border-bottom: 5px solid var(--secondary);
}

.side-container.collapsed.tertiary .pop-text-container {
    border-bottom: 5px solid var(--tertiary);
}

.side-container.collapsed.quaternary .pop-text-container {
    border-bottom: 5px solid var(--quaternary);
}

.side-container.collapsed .side-content-container {
    padding: 1rem;
}