.contact-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
}

.contact-box {
    flex-grow: 1;
    max-width: 660px;
    margin: 0 2rem;
    padding: 1rem 0.5rem;
    min-height: 5rem;
    background-color: var(--secondary-darker);
    border-radius: 1rem;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact-details {
    margin: 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.contact-form {
    flex-grow: 1;
    margin: 0.5rem;

    display: flex;
    flex-direction: column;
}

.contact-form input, .contact-form textarea, .contact-form select {
    padding: 0.5rem;
    border-radius: 0.4rem;
    border: none;
    color: white;
    background: var(--secondary);
}

.contact-form select {
    color: #ffffffaa;
}

.contact-form option {
    color: white;
}

.contact-form textarea {
    resize: vertical;
}

.contact-form input:focus-visible, .contact-form textarea:focus-visible,
.contact-form select:focus-visible {
    outline: 2px solid var(--secondary-darker);
}

.contact-form input::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder {
    color: #ffffffaa;
}

.contact-form input:-moz-placeholder,
.contact-form textarea:-moz-placeholder {
    /* FF 4-18 */
    color: #ffffff;
    opacity: 0.73;
}

.contact-form input:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder {
    /* IE 10+ */
    color: #ffffffaa;
}

.contact-form input::-ms-input-placeholder,
.contact-form textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffffaa;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
    /* modern browser */
    color: #ffffffaa;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
}

.input-container label {
    margin-bottom: 0.25rem;
}

.input-container-submit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
    margin-top: 0.5rem;
}

.input-container-submit input {
    width: 10rem;
    cursor: pointer;

    background-color: var(--tertiary-light);
}
.input-container-submit input:hover {
    background-color: var(--tertiary);
}

.phone-box {
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
}

@media only screen and (max-width: 545px) {
    .contact-details {
        width: 100%;
    }
}

@media only screen and (max-width: 435px) {
    .contact-box {
        margin: 0;
        border-radius: 0;
    }
    .input-container-submit input {
        width: 100%;
    }
}

.socials-container {
    display: flex;
}

.social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    cursor: pointer;
    margin: 0.5rem;
}

@media (hover: hover) {
    .social-icon:hover {
        transform: scale(1.1);
    }
}
