.footer {
	z-index: 2;
	position: relative;
	min-height: 4rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	background-color: #0e0717;
	-webkit-box-shadow: 0px 0px 25px 6px #27143d;
	-moz-box-shadow: 0px 0px 25px 6px #27143d;
	box-shadow: 0px 0px 25px 6px #27143d;

	flex-wrap: wrap;
}

.no-glow {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
