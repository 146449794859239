:root {
  --primary-lightest: #7E46C3;
  --primary-lighter: #572D8B;
  --primary-light: #44236C;
  --primary: #27143D;
  --primary-darker: #1D0F2E;
  --primary-dark: #130A1F;
  --primary-darkest: #0E0717;

  --secondary-lightest: #A6A6C9;
  --secondary-light: #8C8CBA;
  --secondary-lighter: #7373AB;
  --secondary: #54548C;
  --secondary-darker: #3D3D66;
  --secondary-dark: #262640;

  --tertiary-light: #F1558E;
  --tertiary: #D81159;
  --tertiary-dark: #71092F;

  --quaternary-light: #B1C5E7;
  --quaternary-lighter: #7899D4;
  --quaternary: #4574C4;
  --quaternary-darker: #2C4F8C;
}

@font-face {
  font-family: "Oxygen";
  src: local("Oxygen"), url("./fonts/oxygen/Oxygen-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SourceCodePro";
  src: local("SourceCodePro"), url("./fonts/source-code-pro/SourceCodePro-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SecularOne";
  src: local("SecularOne"), url("./fonts/secular-one/SecularOne-Regular.ttf") format("truetype");
  font-weight: normal;
}

html {
  color: white;
  font-family: 'Oxygen';
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select {
  font-family: 'SourceCodePro';
}

p {
  margin: 0;
}

body {
  margin: 0;
  background-color: #130A1F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
