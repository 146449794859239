.grad-announcement-alignment-container {
	padding-top: 4rem;
	display: flex;
	justify-content: center;
}

.grad-announcement-container {
	border: 2px solid black;
	-webkit-box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.75);
	box-shadow: -2px 2px 10px 0px rgba(0, 0, 0, 0.75);
	display: flex;
}

.grad-announcement-image {
	max-height: 600px;
}

@media only screen and (max-width: 870px) {
	.grad-announcement-image {
		max-height: 500px;
	}
}

@media only screen and (max-width: 730px) {
	.grad-announcement-container {
		flex-direction: column;
		max-width: 400px;
	}
	.grad-announcement-alignment-container {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.grad-announcement-image {
		max-width: 400px;
		max-height: unset;
	}
}

@media only screen and (max-width: 490px) {
	.grad-announcement-container {
		max-width: unset;
		border: none;
	}
	.grad-announcement-image {
		max-width: 100vw;
		max-height: unset;
	}
}
