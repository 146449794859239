
.layer {
    z-index: 1;
    position: relative;
    left: 50%;
    width: 150vmax;
    height: 120px;
}

.layer.primary {
    background-color: var(--primary);
}

.layer.secondary {
    background-color: var(--secondary);
}

.layer.tertiary {
    background-color: var(--tertiary);
}

.layer.quaternary {
    background-color: var(--quaternary);
}

.twist-logo {
    z-index: 1;
    position:relative;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    height: 120px;
}