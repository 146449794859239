.App {
  text-align: center;
}

.quaternary-color {
  color: var(--quaternary);
}
.tertiary-color {
  color: var(--tertiary);
}
.secondary-color {
  color: var(--secondary);
}
.primary-color {
  color: var(--primary);
}

.logo {
  height: 2.5rem;
  margin: 0.5rem auto;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.logo.large {
  height: 5rem;
  margin: 1.5rem auto;
}

.container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: calc( 100vh - 4rem );
  width: 100%;
  margin: 0;
  padding: 4rem 0 4rem 0;
  background-color: var(--primary-darker);
  overflow: hidden;
  
  /* font-size: 2.4vw; */
}

.slanted-section {
  z-index: -1;
  margin-top: 8rem;
  padding: 2rem 0;
  margin-bottom: 6rem;
}
.slanted-section::before {
  position: absolute;
  margin-top: calc(-2rem - 74px);
  content: "";
  border-bottom: 0 solid var(--primary-dark);
  border-top: 75px solid transparent;
}
.slanted-section.right::before {
  right: 0px;
  border-right: 0 solid transparent;
  border-left: 100vw solid var(--primary-dark);
}
.slanted-section.left::before {
  left: 0px;
  border-left: 0 solid transparent;
  border-right: 100vw solid var(--primary-dark);
}

.slanted-section::after {
  position: absolute;
  margin-top: calc(2rem - 1px);
  content: "";
  border-bottom: 75px solid transparent;
  border-top: 0 solid var(--primary-dark);
}
.slanted-section.right::after {
  right: 0px;
  border-right: 100vw solid var(--primary-dark);
  border-left: 0 solid transparent;
}
.slanted-section.left::after {
  left: 0px;
  border-left: 100vw solid var(--primary-dark);
  border-right: 0 solid transparent;
}